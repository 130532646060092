<template lang="pug">
article.card.w-100.h-100.d-flex
  .w-100.text-decoration-none.d-flex(@click="navigateToArticle")
    .card__inner
      .card__num.lh-1.text-black.fw-semibold {{ indexOutput }}
      .card__image
        nuxt-img(
          v-if='image',
          :src='image',
          :alt='title',
          format='webp',
          loading='lazy',
          width='215',
          height='215',
          sizes='xs:100px md:180px lg:230px'
        )
      EcreativAtomsHeading.card__title.lh-1(
        v-if='title',
        context='none',
        tag='h3'
      )
        span(v-html='title')
      .card__text.lh-2.display-6.fw-normal.text-black.mb-0(v-if='content', v-html='content')
      .card_icon
        EcreativAtomsSvgIcon(
          iconName="chevronRight",
          isCustom,
          iconSize="sm",
        )
      .card__button
        EcreativAtomsButton(
          buttonType='Link',
          linkType='IsInternal',
          context='secondary',
          linkColor='white',
          fontWeight="bold",
          text='Read More',
          :link="getUrl",
          size='md'
        )
      .card__line
</template>
<script setup>
import { computed } from 'vue';
import EcreativAtomsButton from '../../atoms/Button.vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsSvgIcon from '../../atoms/SvgIcon.vue';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
    default: 'https://picsum.photos/300/300',
  },
  link: {
    type: String,
    default: '#',
  },
  slug: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: 'Some Title Lorem ipsum dolor sit amet.',
  },
  content: {
    type: String,
    default:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid officiis hic aliquam distinctio est possimus. Sequi, repudiandae numquam. Harum quaerat adipisci, accusantium nostrum ex minima dolore omnis ipsa eaque ab.',
  },
  index: {
    type: Number,
    default: 1,
  },
  category: {
    type: String,
    default: 'articles',
  },
});

const indexOutput = computed(() => {
  if (props.index <= 8) {
    return `0${props.index + 1}`;
  }
  return props.index + 1;
});

const getUrl = computed(() => {
  // Check if `props.category` exists and is not an empty string
  if (props.category && props.category.trim().length > 0) {
    return `/${props.category}/${props.slug}`;
  } else {
    // If `props.category` is not provided or is empty, construct URL without it
    return `/${props.slug}`;
  }
});

const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.card {
  container-type: inline-size;
  cursor: pointer;
}

.card__inner {
  display: grid;
  grid-template-areas:
    'number number number'
    'image title icon'
    'line line line';
  grid-template-columns: auto auto clamp(1rem, 7cqi, 2rem);
  row-gap: 1em;
  column-gap: 1em;
}

.card__image {
  grid-area: image;
  width: 25cqi;
  aspect-ratio: 1/1;
}

.card_icon {
  grid-area: icon;
  display: flex;
  align-items: center;
}

.card__title {
  grid-area: title;
  font-size: clamp(1rem, 5cqi, 1.5rem);
  align-items: center;
  display: flex;
  margin-bottom: 0;
}

.card__text {
  font-size: clamp(0.7rem, 5cqi, 0.9rem);
  grid-area: text;
  display: none;
}

.card__num {
  grid-area: number;
  font-size: clamp(1.5rem, 7cqi, 3rem);
}

.card__button {
  display: none;
  grid-area: button;
}

.card__line {
  grid-area: line;
  width: 100%;
  height: 2px;
  background-color: #e8e6e6c4;
}
@container (min-width: 575px) {
  .card_icon {
    display: none;
  }
  .card__image {
    width: 32cqi;
  }
  .card__inner {
    grid-template-areas:
      'number number'
      'image title'
      'image button'
      'line line';
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr auto auto;
  }
  .card__button {
    display: flex;
    align-items: flex-end;
  }
  .card__title {
    width: 48cqi;
  }
}
@container (min-width: 700px) {
  .card__title {
    align-items: flex-start;
  }

  .card__image {
    width: clamp(12rem, 30cqi, 14rem);
  }
  .card__inner {
    grid-template-areas:
      'number number'
      'image title'
      'image text'
      'image button'
      'line line';
    grid-template-columns: auto auto;
    grid-template-rows: auto 1fr auto auto;
    column-gap: 2em;
  }
  .card__title {
    width: 60cqi;
  }
  .card__text {
    width: 60cqi;
    text-overflow: ellipsis;
    /* stylelint-disable */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
  }

  .card__line {
    margin-top: 1rem;
  }
}

@container (min-width: 900px) {
  .card__inner {
    grid-template-areas:
      'number image title'
      'number image text'
      'number image button'
      'number line line';
    grid-template-columns: auto auto auto;
    grid-template-rows: auto 1fr auto auto;
  }
  .card__text {
    height: fit-content;
    align-self: flex-end;
  }
}

.card :deep(.wp-block-quote) {
  margin: 0;
  padding: 0;
  border-left: unset;
  p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0.5rem !important;
  }
  cite {
    font-size: 0.7rem;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 1rem;
    color: var(--ts-body-color);
  }
}
</style>
